import {createBrowserHistory} from "history";
import {applyMiddleware, compose, createStore} from "redux";
import {routerMiddleware} from "connected-react-router";
import promiseMiddleware from "redux-promise";
import thunkMiddleware from "redux-thunk";
import createRootReducer from "./reducers";

const basename = process.env.REACT_APP_BASENAME;

console.log(basename);
export const history = createBrowserHistory({basename: basename});
export default function configureStore(preloadedState) {
    return createStore(
        createRootReducer(history),
        preloadedState,
        compose(
            applyMiddleware(
                routerMiddleware(history),
                promiseMiddleware,
                thunkMiddleware
            ),
        ),
    );
}