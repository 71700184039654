export default class Assignment {
    guid;
    number;
    name;
    description;
    expected;

    constructor(guid, number, name, description, expected) {
        this.guid = guid;
        this.number = number;
        this.name = name;
        this.description = description;
        this.expected = expected;
    }

    static fromResponse(response) {
        return new Assignment(response.guid, response.number, response.name, response.description, response.expected)
    }
}