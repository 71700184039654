import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import AssignmentListReducer from "./AssignmentListReducer";
import AssignmentReducer from "./AssignmentReducer";


const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    assignments: AssignmentListReducer,
    assignment: AssignmentReducer,
});

export default createRootReducer

