import React from 'react';
import ReactDOM from 'react-dom';
import {Route, Switch} from "react-router";
import {ConnectedRouter} from "connected-react-router";
import {Provider} from "react-redux";
import {Container, CssBaseline} from "@material-ui/core";
import {ThemeProvider} from '@material-ui/core/styles';

import configureStore, {history} from "./redux/configureStore";
import theme from './theme';
import AssignmentList from "./components/AssignmentList";
import AssignmentEditor from "./components/AssignmentEditor";
import Header from "./components/Header";

const rootElement = document.getElementById('root');
const preloadedState = {};
ReactDOM.render(
    <Provider store={configureStore(preloadedState)}>
        <ConnectedRouter history={history}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Container>
                    <Header/>
                    <Switch>
                        <Route exact path="/assignment/:assignmentGUID" component={AssignmentEditor}/>
                        <Route exact path="/assignments/" component={AssignmentList}/>
                        <Route component={AssignmentList}/>
                        <Route render={() => <div>"NOT MATCH"</div>}/>
                    </Switch>
                </Container>
            </ThemeProvider>
        </ConnectedRouter>
    </Provider>,
    rootElement
);
