import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {Link as RouterLink, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {createAssignment, getAssignmentList} from "../actions";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import AssignmentDetails from "./AssignmentDetails";
import Fab from "@material-ui/core/Fab";
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    actionLink: {
        marginLeft: 20,
        cursor: "pointer"
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

const headCells = [
    {id: "number", label: "№", props: {align: "left", padding: "none"}, sortable: true},
    {id: "name", label: "Название", props: {align: "left"}, sortable: true},
    {id: "link", label: "", sortable: false},
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}


export default function AssignmentList() {
    const classes = useStyles();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAssignmentList())
    }, []);
    const assignments = useSelector(state => state.assignments);
    const history = useHistory();

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('number');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);

    const [detailsOpen, setDetailsOpen] = useState(false);
    const [detailsAssignment, setDetailsAssignment] = useState({});

    const handleRequestSort = (event, cellId) => {
        const isAsc = orderBy === cellId && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(cellId);
    };

    const createSortHandler = cellId => event => {
        handleRequestSort(event, cellId);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    function handleClick(e, assignment) {
        setDetailsOpen(true);
        setDetailsAssignment(assignment);
    }

    function handleCreateAssignment() {
        dispatch(createAssignment((assignment) => {
            history.push(`/assignment/${assignment.guid}`);
        }))
    }

    return (
        <div className={classes.root}>
            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="assignments table">
                    <TableHead>
                        <TableRow>
                            {headCells.map((cell) =>
                                <TableCell key={cell.id} {...cell.props}>
                                    {cell.sortable
                                        ? <TableSortLabel
                                            active={orderBy === cell.id}
                                            direction={orderBy === cell.id ? order : 'asc'}
                                            onClick={createSortHandler(cell.id)}
                                        >
                                            {cell.label}
                                        </TableSortLabel>
                                        : null
                                    }
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stableSort(assignments, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                            .map(row =>
                                <TableRow key={row.number} onClick={e => handleClick(e, row)}>
                                    <TableCell align="left">{row.number}</TableCell>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="center">
                                        <Link component={RouterLink} className={classes.actionLink}
                                              to={`assignment/${row.guid}`}>
                                            {row.guid}
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[15, 25]}
                component="div"
                count={assignments.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <Fab size="small" color="secondary" aria-label="add" className={classes.margin}
                 onClick={handleCreateAssignment}>
                <AddIcon/>
            </Fab>
            <AssignmentDetails assignment={detailsAssignment} open={detailsOpen}/>
        </div>
    );
}