import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {Link} from "@material-ui/core";
import {Link as RouterLink} from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBarLink: {
        color: "white"
    },

}));

export default function Header() {
    const classes = useStyles();
    return (
        <AppBar position="static">
            <Toolbar variant="dense">
                <Link className={classes.appBarLink} component={RouterLink} to={"/"}>
                    <Typography variant="h6" color="inherit">
                        Редактор Заданий
                    </Typography>
                </Link>
            </Toolbar>
        </AppBar>
    );
}