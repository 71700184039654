import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAssignment, submitAssignment, updateAssignment} from "../actions";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import ReactMarkdown from "react-markdown";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import Fab from "@material-ui/core/Fab";
import PublishIcon from '@material-ui/icons/Publish';


const useStyles = makeStyles((theme) => ({
    preview: {
        padding: theme.spacing(1),
        width: "100%",
        "& img": {
            maxWidth: 800,
        }
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

export default function AssignmentEditor(props) {
    const assignmentGUID = props.match.params.assignmentGUID;
    const classes = useStyles();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAssignment(assignmentGUID));
    }, [assignmentGUID]);

    const assignment = useSelector(state => {
        return state.assignment;
    });

    function handleChangeNumber(e) {
        assignment.number = e.target.value;
        dispatch(updateAssignment(assignment));
    }

    function handleChangeName(e) {
        assignment.name = e.target.value;
        dispatch(updateAssignment(assignment));

    }

    function handleChangeExpected(e) {
        assignment.expected = e.target.value;
        dispatch(updateAssignment(assignment));
    }

    function handleChangeDescription(e) {
        assignment.description = e.target.value;
        dispatch(updateAssignment(assignment));
    }

    function handleUpdate() {
        console.log(assignment);
        dispatch(submitAssignment(assignment))
    }

    return (
        <div>
            <FormGroup row>
                <TextField
                    autoFocus
                    margin="dense"
                    id="canvasCourseName"
                    label={`Номер`}
                    fullWidth
                    value={assignment.number}
                    onChange={handleChangeNumber}
                    variant="outlined"
                    type="number"
                />
            </FormGroup>
            <FormGroup row>
                <TextField
                    margin="dense"
                    id="canvasCourseName"
                    label={`Название`}
                    fullWidth
                    value={assignment.name}
                    onChange={handleChangeName}
                    variant="outlined"
                />
            </FormGroup>
            <FormGroup row>
                <TextField
                    margin="dense"
                    id="canvasCourseName"
                    label={`Результат`}
                    fullWidth
                    value={assignment.expected}
                    onChange={handleChangeExpected}
                    variant="outlined"
                />
            </FormGroup>
            <FormGroup row>
                <TextField
                    margin="dense"
                    id="canvasCourseName"
                    label={`Описание`}
                    fullWidth
                    multiline
                    rows={10}
                    value={assignment.description}
                    onChange={handleChangeDescription}
                    variant="outlined"
                />
            </FormGroup>
            <FormGroup row>
                <FormLabel>Просмотр</FormLabel>
                <Paper variant="outlined" className={classes.preview}>
                    <ReactMarkdown source={assignment.description}/>
                </Paper>
            </FormGroup>
            <Fab size="small" color="secondary" aria-label="submit" className={classes.margin} onClick={handleUpdate}>
                <PublishIcon/>
            </Fab>
        </div>
    );

}