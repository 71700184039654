import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ReactMarkdown from "react-markdown";
import Fab from "@material-ui/core/Fab";
import AssignmentIcon from '@material-ui/icons/Assignment';
import CommandViewer from "./CommandViewer";

const useStyles = makeStyles((theme) => ({
    visible: {
        display: "inherit",
        "& img": {
            maxWidth: 800,
        }
    },
    hidden: {
        display: "none"
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

export default function AssignmentDetails(props) {
    const {assignment, open} = props;
    const classes = useStyles();

    return (
        <div className={open ? classes.visible : classes.hidden}>
            <Typography variant={"h4"}>{assignment.name}</Typography>
            <ReactMarkdown source={assignment.description}/>
            <Typography variant={"h4"}>Результат:</Typography>
            <CommandViewer text={assignment.expected}/>
            <Fab size="small" color="secondary" aria-label="add" className={classes.margin}
                 href={`/rzd/assignment/${assignment.guid}`}>
                <AssignmentIcon/>
            </Fab>
        </div>
    );
}