import React from 'react';
import "./editor.css"
import {makeStyles} from "@material-ui/core/styles";

const REGEXP = /[A-Z\[\]@^_\\]/;
const LETTER_WIDTH = 39;

const useStyles = makeStyles((theme) => ({
    "container": {
        backgroundColor: "#27349e",
        minWidth: "800px",
        minHeight: "600px",
    },
    "editor": {
        display: "inline-flex",
        flexWrap: "wrap",
    },
    "wrapper": {
        marginTop: "10px",
        marginLeft: "4px",
        textAlign: "center",
        borderLeft: "4px solid transparent",
        minHeight: "50px",
        minWidth: "35px",
    },
    "letter": {
        fontFamily: "monospace",
        fontSize: "40pt",
        color: "white",
        fill: "white",
    },
    "important": {
        color: "#c9c700",
        fill: "#c9c700",
        stroke: "#c9c700",
    },
    "wrapper-important": {
        borderBottom: "4px solid #c9c700"
    }
}));

function getSymbols(text, classes) {
    const symbols = [];
    if (text !== undefined && text !== "") {
        let idx = 0;
        for (const letter of text) {
            let symbolClassName = classes.letter;
            const isImportant = letter.match(REGEXP);
            if (isImportant) {
                symbolClassName += " " + classes.important;
            }
            symbols.push(
                <div key={idx} className={classes.wrapper}>
                    <svg width="35" height="50" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        {isImportant &&
                        <line x1="0" x2="31" y1="50" y2="50" strokeWidth="5" className={symbolClassName}/>}
                        <text x="0" y="40" className={symbolClassName}>{letter}</text>
                    </svg>
                </div>
            );
            idx++;
        }
    }
    return symbols;
}


export default function CommandViewer(props) {
    const text = props.text;
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.editor}>
                {getSymbols(text, classes)}
            </div>
        </div>
    );
}

